<template lang="pug">
Container(:no-overflow='Boolean(greaterThan("desktop"))')
	UiBreadcrumbs

	div(class='ArticlesPageWrapper')
		h1 {{ header }}
		div(class='ArticlesPage', :class='{ NoAside: !articleState.elements.length }')
			div(class='ArticlesPage--Content')
				UiImage(class='Preview', :image='articleState.preview', width='1000px', lazy)
				UiProse(class='Article', :html='articleState.article', awesome)

				div(class='Elements')
					template(
						v-for='({ article_elements_id: element }, index) in articleState.elements',
						:key='index'
					)
						//- Element default
						div(v-if='element.type === ArticleElements.type.DEFAULT', :id='`e-${index}`', class='Element default')
							h2(v-if='element.header') {{ element.header }}
							UiProse(v-if='element.content', :html='element.content', awesome)

						//- Element subContentBlock
						div(
							v-if='element.type === ArticleElements.type.SUB_CONTENT_BLOCK',
							:id='`e-${index}`',
							class='Element subContentBlock'
						)
							h2(v-if='element.header') {{ element.header }}
							UiProse(v-if='element.content', :html='element.content', awesome)
							div(v-if='element.subContent.length', class='SubContent')
								template(
									v-for='({ style, header, content }, subIndex) in element.subContent',
									:key='subIndex'
								)
									div(v-if='style === "default"', :id='`e-${index}-${subIndex}`', class='SubElement default')
										h3(v-if='header') {{ header }}
										UiProse(v-if='content', :html='content', awesome)

									div(v-if='style === "white"', :id='`e-${index}-${subIndex}`', class='SubElement white')
										h3(v-if='header') {{ header }}
										UiProse(v-if='content', :html='content', awesome)

									div(v-if='style === "gray"', :id='`e-${index}-${subIndex}`', class='SubElement gray')
										h3(v-if='header') {{ header }}
										UiProse(v-if='content', :html='content', awesome)

						//- Element whiteBlock
						div(
							v-if='element.type === ArticleElements.type.WHITE_BLOCK',
							:id='`e-${index}`',
							class='Element whiteBlock'
						)
							h2(v-if='element.header') {{ element.header }}
							UiProse(v-if='element.content', :html='element.content', awesome)

						//- Element grayBlock
						div(
							v-if='element.type === ArticleElements.type.GRAY_BLOCK',
							:id='`e-${index}`',
							class='Element grayBlock'
						)
							h2(v-if='element.header') {{ element.header }}
							UiProse(v-if='element.content', :html='element.content', awesome)

						//- Element alsoReadBlock
						div(v-if='element.type === ArticleElements.type.ALSO_READ_BLOCK', class='Element alsoReadBlock')
							h2 Читайте также
							p
								strong {{ element.articleLink.seo.header }}
							p
								UiButton(
									:to='getArticlePageUrl(element.articleLink.alias)',
									:size='greaterThan("mobile") ? "md" : "sm"',
									variant='primary',
									theme='outline',
									iconRight='system/arrow-top-right',
									iconRightSize='sm',
									rounded
								) Читать статью

						//- Element imageBlock
						div(v-if='element.type === ArticleElements.type.IMAGE_BLOCK', class='Element imageBlock')
							UiImage(class='Image', :image='element.image', width='400px', lazy)

						//- Element employeesBlock
						div(
							v-if='element.type === ArticleElements.type.EMPLOYEES_BLOCK',
							:id='`e-${index}`',
							class='Element employeesBlock'
						)
							h2(v-if='element.header') {{ element.header }}
							UiProse(v-if='element.content', :html='element.content', awesome)
							SliderServicesEmployees(:employees='element.employees')

						//- Element servicesBlock
						div(
							v-if='element.type === ArticleElements.type.SERVICES_BLOCK',
							:id='`e-${index}`',
							class='Element servicesBlock'
						)
							h2(v-if='element.header') {{ element.header }}
							UiProse(v-if='element.content', :html='element.content', awesome)
							SliderServicesWithoutGroups(:services='element.services')

						//- Element advantagesBlock
						div(
							v-if='element.type === ArticleElements.type.ADVANTAGES_BLOCK',
							:id='`e-${index}`',
							class='Element advantagesBlock'
						)
							h2(v-if='element.header') {{ element.header }}
							UiProse(v-if='element.content', :html='element.content', awesome)
							SliderMigrationCases(class='Cases', :cases='element.advantages')

			template(v-if='articleState.elements.length')
				div(v-if='greaterThan("mobileWide")', class='ArticlesPage--Aside')
					div(class='Content Prose Awesome')
						h2 Содержание
						ol
							template(
								v-for='({ article_elements_id: element }, index) in articleState.elements',
								:key='index'
							)
								li(
									v-if='element.type !== ArticleElements.type.ALSO_READ_BLOCK && element.type !== ArticleElements.type.IMAGE_BLOCK && element.header'
								)
									a(:href='`#e-${index}`', :class='{ Active: hasActiveElement === `e-${index}` }') {{ element.header }}
									ol(v-if='element.type === ArticleElements.type.SUB_CONTENT_BLOCK', class='SubContent')
										template(v-for='({ header }, subIndex) in element.subContent', :key='subIndex')
											li(v-if='header')
												a(
													:href='`#e-${index}-${subIndex}`',
													:class='{ Active: hasActiveElement === `e-${index}-${subIndex}` }'
												) {{ header }}

				UiDropdown(v-else, class='AsideDropdown', theme='select', placement='bottom')
					template(#label)
						UiButton(
							class='Phone',
							variant='secondary',
							theme='filled',
							size='xl',
							rounded,
							iconRight='system/arrow-down',
							iconRightSize='sm'
						) Содержание
					template(#default)
						div(class='AsideDropdown--Wrapper')
							div(class='Content Prose Awesome')
								ol
									template(
										v-for='({ article_elements_id: element }, index) in articleState.elements',
										:key='index'
									)
										li(
											v-if='element.type !== ArticleElements.type.ALSO_READ_BLOCK && element.type !== ArticleElements.type.IMAGE_BLOCK && element.header'
										)
											a(:href='`#e-${index}`', :class='{ Active: routeHash === `#e-${index}` }') {{ element.header }}
											ol(v-if='element.type === ArticleElements.type.SUB_CONTENT_BLOCK', class='SubContent')
												template(v-for='({ header }, subIndex) in element.subContent', :key='subIndex')
													li(v-if='header')
														a(
															:href='`#e-${index}-${subIndex}`',
															:class='{ Active: routeHash === `#e-${index}-${subIndex}` }'
														) {{ header }}

template(v-for='{ unit } in articleState.units', :key='unit')
	Container(v-if='unit === Articles.units.ABOUT')
		UnitAbout

	Container(v-if='unit === Articles.units.ARTICLES')
		UnitArticles

	Container(v-if='unit === Articles.units.BENEFITS')
		UnitBenefits

	Container(v-if='unit === Articles.units.CASES')
		UnitCases

	Container(v-if='unit === Articles.units.CHOOSE')
		UnitChoose

	Container(v-if='unit === Articles.units.CONSULTATION_EMPLOYEES')
		UnitConsultation(type='employees')

	Container(v-if='unit === Articles.units.CONSULTATION_VNZH')
		UnitConsultation(type='vnzh')

	Container(v-if='unit === Articles.units.HISTORY')
		UnitHistory

	Container(v-if='unit === Articles.units.MANAGER')
		UnitManager

	Container(v-if='unit === Articles.units.MANAGER2')
		UnitManager2

	Container(v-if='unit === Articles.units.MIGRATION')
		UnitMigration

	Container(v-if='unit === Articles.units.PRACTICE')
		UnitPractice

	Container(v-if='unit === Articles.units.REPUTATION')
		UnitReputation

	Container(v-if='unit === Articles.units.REVIEWS_GROUP')
		UnitReviewsGroup

	Container(v-if='unit === Articles.units.REVIEWS')
		UnitReviews

	Container(v-if='unit === Articles.units.VICTORIES')
		UnitVictories

	Container(v-if='unit === Articles.units.VIDEO_REVIEWS')
		UnitVideoReviews

UiSubscribe
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';
import { Articles } from '~/models/articles';
import { Resolver } from '~/models/resolver';
import { ArticleElements } from '~/models/article-elements';

const routeHash = ref<string>('');
const hasActiveElement = ref<string>('');

/**
 * Define props
 */
const { alias } = defineProps<{
	alias: string;
}>();

/**
 * Define hooks
 */
const { header } = useMeta();
const { articleState, fetchArticle } = useArticle(alias);
const { greaterThan } = usePlatform();
const { getPageUrlByType, fetchResolver } = useResolver();
const route = useRoute();

/**
 * Get article page url by alias
 */
const getArticlePageUrl = (alias: string) =>
	joinURL(getPageUrlByType(Resolver.Page.ARTICLES) || 'articles', alias);

/**
 * Define fetch
 */
await fetchArticle(alias);
await fetchResolver();

const handleScroll = () => {
	const elements = document.querySelectorAll('[id^="e-"]'); // Получаем все элементы, id которых начинается с "e-"
	let lastVisibleId = null;

	for (const element of elements) {
		const rect = element.getBoundingClientRect();

		if (rect.top >= 0 && rect.top < window.innerHeight) {
			lastVisibleId = element.id;
			break;
		}
	}

	if (lastVisibleId) {
		hasActiveElement.value = lastVisibleId;
	}
};

/**
 * On mounted add active class to active element
 */
onMounted(() => {
	routeHash.value = route.hash;

	watchEffect(() => {
		routeHash.value = route.hash;
	});

	window.addEventListener('scroll', handleScroll);

	/**
	 * Add scroll-behavior: smooth; to html
	 */
	setTimeout(() => {
		document.documentElement.style.scrollBehavior = 'smooth';
	}, 100);
});
onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll);

	/**
	 * Remove scroll-behavior: smooth; from html
	 */
	document.documentElement.style.scrollBehavior = 'auto';
});
</script>

<style lang="less" scoped>
.ArticlesPageWrapper {
	padding: 1rem 0 1rem 0;
	& > h1 {
		max-width: 100%;
		.box(block; 100%; auto; none);
		.text(@ColorBase; 1.4em 1.25rem @bold; left; uppercase none);
	}
	@media all and (min-width: @desktop) {
		padding: 4rem 0 4rem 0;
		& > h1 {
			.text(@ColorBase; 1.4em 2.5rem @bold; left; uppercase none);
		}
	}
}
.ArticlesPage {
	padding: 1rem 0 1rem 0;
	.box(grid; 100%; auto; none; 2rem; auto; 1fr; start; center);
	&--Content {
		.grid(2 3; 1 2);
		.box(grid; 100%; auto; none; 2rem; auto; 1fr; start; start);
		& > .Preview {
			object-fit: cover;
			.grid(1 span);
			.grid-aspect(8; 3);
			.box(block; 100%; auto; fade(@ColorBase, 5%));
			.border-radius(@BorderRadiusMedium);
		}
		& > .Article {
			.grid(1 span);
			.text(@ColorBase; 1.4em 1rem @regular; left; none none);
		}
		& > .Elements {
			.grid(1 span);
			.box(grid; 100%; auto; none; 2rem; auto; 1fr; start; start);
			& h2 {
				margin: 0 0 1rem 0;
				.text(@ColorBase; 1.4em 1.25rem @bold; left; unset none);
			}
			& h3 {
				margin: 0 0 1rem 0;
				.text(@ColorBase; 1.4em 1.125rem @bold; left; unset none);
			}
			& > .Element {
				.grid(1 span);
				&.default {
					.box(block; 100%; auto; none);
				}
				&.subContentBlock {
					.box(block; 100%; auto; none);
					& > .SubContent {
						.grid(1 span);
						.box(grid; 100%; auto; none; 1rem; 1fr; 1fr; start; start);
						& > .SubElement {
							.grid(1 span);
							&.default {
								padding: 1.5rem 0 0 0;
								.box(block; 100%; auto; none);
								& > h3 {
									text-transform: uppercase;
									.text(fade(@ColorBase, 50%); 1.4em 1rem);
								}
							}
							&.white {
								padding: 1.5rem 1.5rem 0 1.5rem;
								.box(block; 100%; auto; @ColorWhite);
								.border-radius(@BorderRadiusMedium);
							}
							&.gray {
								padding: 1.5rem 1.5rem 0 1.5rem;
								.box(block; 100%; auto; fade(@ColorBase, 10%));
								.border-radius(@BorderRadiusMedium);
							}
						}
					}
				}
				&.whiteBlock {
					padding: 1.5rem 1.5rem 0 1.5rem;
					.box(block; 100%; auto; @ColorWhite);
					.border-radius(@BorderRadiusMedium);
				}
				&.grayBlock {
					padding: 1.5rem 1.5rem 0 1.5rem;
					.box(block; 100%; auto; fade(@ColorBase, 10%));
					.border-radius(@BorderRadiusMedium);
				}
				&.alsoReadBlock {
					padding: 1.5rem 8rem 1.5rem 1.5rem;
					.box(flex; 100%; auto; @ColorWhite; flex-start; flex-start; flex-start; wrap column);
					.background(@ColorWhite; '@/images/glossy.png'; no-repeat; right center; 8rem 8rem);
					.border-radius(@BorderRadiusMedium);
				}
				&.imageBlock {
					.box(block; 100%; auto; none);
					& > .Image {
						object-fit: cover;
						.grid-aspect(8; 3);
						.box(grid; 100%; auto; none);
						.border-radius(@BorderRadiusMedium);
					}
				}
				&.employeesBlock {
					.box(block; 100%; auto; none);
				}
				&.advantagesBlock {
					.box(block; 100%; auto; none);
				}
			}
		}
	}
	&--Aside {
		padding: 1.5rem;
		.grid(1 2; 1 2; stretch);
		.box(grid; 100%; auto; @ColorWhite; 1rem; auto; 1fr; start; start);
		.border-radius(@BorderRadiusMedium);

		& > h2 {
			.grid(1 span);
			text-transform: none;
		}
		& > .Content {
			.grid(1 span);
			.box(block; 100%; auto; none);
			.text(@ColorBase; 1.4em 1rem @regular; left; none none);
		}
	}

	@media all and (min-width: @tablet-small) and (max-width: (@laptop - 1px)) {
		.box(grid; 100%; auto; none; 2rem; auto; 1fr; start; center);
		&--Aside {
			padding: 2rem;
		}
	}
	@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
		.box(grid; 100%; auto; none; 2rem; auto; 1fr; start; center);
		&--Aside {
			padding: 2rem;
		}
	}
	@media all and (min-width: @desktop) {
		.box(grid; 100%; auto; none; 2rem 6rem; auto; 1.6fr 1fr; start; center);
		& > h1 {
			.grid(1 2; 1 3);
			.text(@ColorBase; 1.4em 2.5rem @bold; left; uppercase none);
		}
		&--Content {
			overflow-x: hidden;
			.grid(1 2; 1 2);
			& > .Article {
				.grid(1 span);
				.text(@ColorBase; 1.4em 1.125rem @regular; left; none none);
			}
			& > .Elements {
				& h2 {
					.text(@ColorBase; 1.4em 2rem @bold; left; unset none);
				}
				& h3 {
					.text(@ColorBase; 1.4em 1.5rem @bold; left; unset none);
				}

				& > .Element {
					&.subContentBlock {
						& > .SubContent {
							.box(grid; 100%; auto; none; 2rem; auto; 1fr; start; start);
							& > .SubElement {
								&.default {
									padding: 1.5rem 0 0 0;
									& h3 {
										.text(fade(@ColorBase, 50%); 1.4em 1.25rem);
									}
								}
								&.white {
									padding: 3rem 3rem 1.5rem 3rem;
								}
								&.gray {
									padding: 3rem 3rem 1.5rem 3rem;
								}
							}
						}
					}
					&.whiteBlock {
						padding: 3rem 3rem 1.5rem 3rem;
					}
					&.grayBlock {
						padding: 3rem 3rem 1.5rem 3rem;
					}
					&.alsoReadBlock {
						padding: 3rem 14rem 3rem 3rem;
						.background(@ColorWhite; '@/images/glossy.png'; no-repeat; right center; 14rem 14rem);
					}
				}
			}
		}
		&--Aside {
			padding: 2.5rem;
			overflow-y: auto;
			.sticky(1; none; 1rem 0 0 0);
			.grid(1 2; 2 3; start);
			.calc(max-height; ~'100vh - 2rem');
			& > .Content {
				.grid(1 span);
				.box(block; 100%; auto; none);
				.text(@ColorBase; 1.4em 1.125rem @medium; left; none none);
			}
		}

		&.NoAside {
			& > .ArticlesPage--Content {
				.grid(1 span; 1 3);
			}
		}
	}
}
</style>

<style lang="less">
.ArticlesPage {
	&--Content {
		& > .Elements {
			& p {
				margin: 1rem 0;
				.text(@ColorBase; 1.6em 1rem @regular; left; unset none);
			}
		}
	}
	&--Aside {
		& > .Content {
			& a {
				.text(@ColorBase; 1.4em 1rem @medium; left; none none);
				&.Active {
					.text(@ColorPrimary);
				}
			}
			& ol {
				margin: 0.5rem 0 0 0;
			}
			& li {
				margin: 0 0 0.5rem 0;
			}
		}
	}

	@media all and (min-width: @desktop) {
		&--Content {
			& > .Elements {
				& p {
					margin: 1rem 0;
					.text(@ColorBase; 1.6em 1.125rem @regular; left; unset none);
				}
			}
		}
		&--Aside {
			& > .Content {
				& a {
					.text(@ColorBase; 1.4em 1.125rem @medium; left; none none);
					&.Active {
						.text(@ColorPrimary);
					}
				}
				& ol {
					margin: 1rem 0 0 0;
				}
				& li {
					margin: 0 0 1rem 0;
				}
			}
		}
	}
}

.AsideDropdown {
	.box(block; 100%; auto; none);

	& > .UiButton {
		.box(100%);
		& > .UiIcon {
			margin-bottom: 0px !important;
		}
	}
	&--Wrapper {
		padding: 1.5rem;
		.box(grid; 100%; auto; @ColorWhite; 1rem; auto; 1fr; start; start);
		.border-radius(@BorderRadiusMedium);
		.calc(max-width; ~'calc(100% - 2rem)');
		& > .Content {
			& a {
				.text(@ColorBase; 1.4em 1rem @medium; left; none none);
				&.Active {
					.text(@ColorPrimary);
				}
			}
			& ol {
				margin: 0.5rem 0 0 0;
			}
			& li {
				margin: 0 0 0.5rem 0;
			}
		}
	}
}
</style>
